import React, { useState, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import Fuse from 'fuse.js';
import L from 'leaflet';
import '../App.css';

const VehicleSearch = ({ vehicles = { police: [], fire: [], all: [] }, onVehicleSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [tempMarker, setTempMarker] = useState(null);
  const [isSearchingAddress, setIsSearchingAddress] = useState(false);
  const map = useMap();
  const wrapperRef = useRef(null);
  const fuseRef = useRef(null);

  // Initialize Fuse with flattened vehicle array
  useEffect(() => {
    const allVehicles = [...(vehicles.police || []), ...(vehicles.fire || [])];
    const fuseOptions = {
      keys: ['displayName'],
      threshold: 0.5,
      distance: 100,
      findAllMatches: true,
      shouldSort: true,
      minMatchCharLength: 2,
      tokenize: true,
      matchAllTokens: false,
      location: 0,
      maxPatternLength: 32
    };
    fuseRef.current = new Fuse(allVehicles, fuseOptions);
  }, [vehicles]);

  // Clean up temporary marker when component unmounts
  useEffect(() => {
    return () => {
      if (tempMarker) {
        map.removeLayer(tempMarker);
      }
    };
  }, [map, tempMarker]);

  const searchAddress = async (query) => {
    console.log('Initiating address search for query:', query);
    try {
        const mapCenter = map.getCenter();
        const url = `/api/address-search?${new URLSearchParams({
            query: query,
            lat: mapCenter.lat,
            lng: mapCenter.lng,
            zoom: map.getZoom()
        })}`;
        
        console.log('Making request to:', url);
        
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        });

        console.log('Response status:', response.status);
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Address search results:', data);
        
        return data;
    } catch (error) {
        console.error('Error in address search:', error);
        return [];
    }
  };

  const logSearch = async (searchType, searchTerm) => {
    try {
        await fetch('/api/audit-trail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                action: 'SEARCH',
                additional_info: `${searchType} search for: ${searchTerm}`
            })
        });
    } catch (error) {
        console.warn('Failed to log search:', error);
    }
  };

  const handleSearch = async (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    setShowDropdown(true);
    setIsSearchingAddress(false);

    if (query.length < 2) {
        setResults([]);
        return;
    }

    setIsLoading(true);
    try {
        // First check vehicles
        let vehicleResults = [];
        if (fuseRef.current) {
            vehicleResults = fuseRef.current.search(query)
                .slice(0, 5)
                .map(result => ({
                    ...result.item,
                    type: 'vehicle'
                }));
        }

        if (isSearchingAddress) {
            // Only search addresses if user clicked the prompt
            const mapCenter = map.getCenter();
            const addressResults = await searchAddress(query, {
                lat: mapCenter.lat,
                lng: mapCenter.lng,
                zoom: map.getZoom()
            });
            
            // If no address results found, show a message
            if (addressResults.length === 0) {
                setResults([{
                    displayName: `No addresses found for "${query}"`,
                    type: 'no-results',
                    query: query
                }]);
            } else {
                setResults(addressResults);
            }
        } else {
            // Always add the address search option at the end of vehicle results
            setResults([
                ...vehicleResults,
                {
                    displayName: `Search "${query}" as an address?`,
                    type: 'address-prompt',
                    query: query
                }
            ]);
        }
    } catch (error) {
        console.error('Error in handleSearch:', error);
        setResults([{
            displayName: 'Error searching for address',
            type: 'error',
            query: query
        }]);
    } finally {
        setIsLoading(false);
    }
  };

  const handleSelect = async (result) => {
    if (result.type === 'address-prompt') {
        await logSearch('ADDRESS', result.query);
        setIsSearchingAddress(true);
        setIsLoading(true);
        try {
            const addressResults = await searchAddress(result.query);
            if (addressResults.length === 0) {
                // Show no results message
                setResults([{
                    displayName: `No addresses found for "${result.query}"`,
                    type: 'no-results',
                    query: result.query
                }]);
            } else {
                setResults(addressResults);
            }
        } catch (error) {
            console.error('Error searching address:', error);
            setResults([{
                displayName: 'Error searching for address',
                type: 'error',
                query: result.query
            }]);
        } finally {
            setIsLoading(false);
        }
        return;
    }

    if (result.type === 'vehicle') {
        await logSearch('VEHICLE', result.displayName);
        onVehicleSelect(result);
        map.setView([result.latitude, result.longitude], 17);
    } else if (result.type === 'address') {
        await logSearch('ADDRESS', result.displayName);
        if (tempMarker) {
            map.removeLayer(tempMarker);
        }

        const marker = L.marker([result.latitude, result.longitude], {
            icon: L.divIcon({
                className: 'custom-div-icon',
                html: '📍',
                iconSize: [30, 30],
                iconAnchor: [15, 30]
            })
        }).addTo(map);

        marker.bindPopup(result.displayName).openPopup();
        setTempMarker(marker);

        setTimeout(() => {
            map.removeLayer(marker);
            setTempMarker(null);
        }, 10000);

        map.setView([result.latitude, result.longitude], 17);
    }
    setShowDropdown(false);
    setSearchTerm('');
    setIsSearchingAddress(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="search-container" ref={wrapperRef}>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Search vehicles or addresses..."
        className="search-input"
        autoComplete="off"
      />
      {showDropdown && (results.length > 0 || isLoading) && (
        <div className="search-results">
          {isLoading && <div className="search-loading">Loading...</div>}
          {results.map((result, index) => (
            <div
              key={index}
              className={`search-result-item ${
                result.type === 'address-prompt' ? 'address-prompt' : 
                result.type === 'no-results' ? 'no-results' :
                result.type === 'error' ? 'error' : ''
              }`}
              onClick={() => result.type !== 'no-results' && result.type !== 'error' && handleSelect(result)}
            >
              <div className="result-icon">
                {result.type === 'vehicle' ? '🚗' : 
                 result.type === 'address-prompt' ? '🔍' : 
                 result.type === 'no-results' ? '⚠️' :
                 result.type === 'error' ? '❌' : '📍'}
              </div>
              <div className="result-details">
                <div className="result-name">{result.displayName}</div>
                <div className="result-type">
                  {result.type === 'vehicle' ? 'Vehicle' : 
                   result.type === 'address-prompt' ? 'Try Address Search' :
                   result.type === 'no-results' ? 'Try a different search' :
                   result.type === 'error' ? 'Error' : 'Address'}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VehicleSearch;
