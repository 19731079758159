import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    MapContainer, TileLayer, CircleMarker, Polygon,
    Polyline, Tooltip, useMap, useMapEvents
} from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-tooltip-layout';
import 'leaflet-draw';
import '../App.css';
import { useLocation } from 'react-router-dom';
import * as turf from '@turf/turf';
import { Modal } from 'react-bootstrap';
import DrawingTools from './DrawingTools';
import StyledIncidentForm from './StyledIncidentForm';
import IncidentModal from './IncidentModal';
import CloseIncidentModal from './CloseIncidentModal';
import EditMarkersModal from './EditMarkersModal';
import VehicleLayer from './VehicleLayer';
import VehicleTracker from './VehicleTracker';
import VehicleSearch from './VehicleSearch';
import LayerControlModal from './LayerControlModal';
import NameColorForm from './NameColorForm';
import logo from '../images/icons/logo.png';
import { PERMISSIONS, hasPermission } from '../utils/permissions';
import StarChaseLayer from './StarChaseLayer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WeatherOverlay from './WeatherOverlay';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WeatherAlerts from './WeatherAlerts';
import CADAlerts from './CADAlerts';
import IncidentLayerManager from './IncidentLayerManager';
import MobileMode from './MobileMode';
import MobileLocationsLayer from './MobileLocationsLayer';
import io from 'socket.io-client';

// Import the marker images
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Import toolbar icons
import homeIcon from '../images/icons/home.png';
import layerIcon from '../images/icons/layer.png';
import plusIcon from '../images/icons/plus-symbol-button.png';
import editIcon from '../images/icons/edit.png';
import closeIcon from '../images/icons/close.png';
import rosterIcon from '../images/icons/people.png';
import adminIcon from '../images/icons/gear.png';

// Import mobile icon
import mobileIcon from '../images/icons/mobile.svg';

// Import drawing tool icons
import circleIcon from '../images/icons/circle.png';
import squareIcon from '../images/icons/square.png';
import polygonIcon from '../images/icons/polygon.png';
import markerIconImg from '../images/icons/marker.png';
import lineIcon from '../images/icons/line.png';
import fillIcon from '../images/icons/fill.png';

// Fix the default icon paths
L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow
});

const API_URL = process.env.REACT_APP_API_URL || 'https://merlin.westchesterrtc.com';
const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const MAP_URLS = {
    openStreetMap: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    googleMaps: `https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&key=${googleApiKey}`,
    googleSatellite: `https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=${googleApiKey}`,
    googleHybrid: `https://mt1.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&key=${googleApiKey}`
};

function ZoomToIncident({ incident }) {
    const map = useMap();

    useEffect(() => {
        if (incident) {
            map.setView([incident.location_lat, incident.location_long], 14);
        }
    }, [incident, map]);

    return null;
}

function MapClickHandler({ setIncidentLocation, setMarkerPosition }) {
    const map = useMapEvents({
        click(e) {
            setIncidentLocation(e.latlng);
            setMarkerPosition(e.latlng);
        },
    });

    useEffect(() => {
        const handleLocationFound = (e) => {
            setMarkerPosition(e.latlng);
        };

        map.on('locationfound', handleLocationFound);

        return () => {
            map.off('locationfound', handleLocationFound);
        };
    }, [map, setMarkerPosition]);

    return null;
}

function VehicleSearchHandler({ vehicles, onVehicleSelect }) {
    const map = useMap();

    const handleVehicleSelect = useCallback((vehicle) => {
        const { latitude, longitude } = vehicle;
        map.setView([latitude, longitude], 14);
        onVehicleSelect(vehicle);
    }, [map, onVehicleSelect]);

    return <VehicleSearch vehicles={vehicles} onVehicleSelect={handleVehicleSelect} />;
}

const Toolbar = ({ homePosition, setShowLayerModal, setShowForm, setShowEditMarkersModal, setShowCloseModal, user, isMobileMode, onMobileToggle }) => {
    const map = useMap();
    const navigate = useNavigate(); 

    const handleHomeClick = () => {
        if (map.resetVehicleFollowing) {
            map.resetVehicleFollowing();
        }
        
        map.setView(homePosition, 13);
    };

    const handleRosterClick = (event) => {
        event.preventDefault();
        window.open('/roster', '_blank');
    };

    const handleAdminClick = (event) => {
        event.preventDefault();
        const userData = {
            userId: user.userId,
            userName: user.name,
            userAvatar: user.avatar,
            permissions: user.permissions,
            userEmail: user.email
        };
        const encodedUser = encodeURIComponent(JSON.stringify(userData));
        window.open(`${window.location.origin}/admin?user=${encodedUser}`, '_blank');
    };

    return (
        <div className="overlay-left">
            <button onClick={handleHomeClick} className="toolbar-button" title="Home">
                <img src={homeIcon} alt="Home" />
            </button>
            <button onClick={() => setShowLayerModal(true)} className="toolbar-button" title="Layer Control">
                <img src={layerIcon} alt="Layer Control" />
            </button>
            {setShowForm && (
                <button onClick={() => setShowForm(true)} className="toolbar-button" title="New Incident">
                    <img src={plusIcon} alt="New Incident" />
                </button>
            )}
            <button onClick={() => setShowEditMarkersModal(true)} className="toolbar-button" title="Edit Markers">
                <img src={editIcon} alt="Edit Markers" />
            </button>
            <button onClick={() => setShowCloseModal(true)} className="toolbar-button" title="Close Incident">
                <img src={closeIcon} alt="Close Incident" />
            </button>
            <button onClick={handleRosterClick} className="toolbar-button" title="Roster">
                <img src={rosterIcon} alt="Roster" />
            </button>
            {hasPermission(user, PERMISSIONS.ADMIN) && (
                <button onClick={handleAdminClick} className="toolbar-button" title="Admin Panel">
                    <img src={adminIcon} alt="Admin" />
                </button>
            )}
            <button 
                onClick={onMobileToggle} 
                className={`toolbar-button ${isMobileMode ? 'active' : ''}`} 
                title={isMobileMode ? "Exit Mobile Mode" : "Enter Mobile Mode"}
            >
                <img src={mobileIcon} alt="Mobile Mode" />
            </button>
        </div>
    );
};

function ZoomHandler({ onZoomChange }) {
    const map = useMapEvents({
        zoomend: () => {
            const zoom = map.getZoom();
            onZoomChange(zoom);
        },
    });
    return null;
}

function DrawnItemsLayer({ selectedIncident, drawnItems }) {
    const map = useMap(); // Get the map instance from react-leaflet
    
    useEffect(() => {
        if (selectedIncident && drawnItems) {
            // Clear existing layers
            drawnItems.clearLayers();

            const fetchAndRenderDrawnItems = async () => {
                try {
                    const response = await fetch(
                        `${API_URL}/api/drawn-items?incident_id=${selectedIncident.incident_id}`,
                        { credentials: 'include' }
                    );
                    
                    if (!response.ok) throw new Error('Failed to fetch drawn items');
                    
                    const items = await response.json();
                    console.log('Fetched drawn items:', items);

                    items.forEach(item => {
                        if (item.active && item.geojson) {
                            const layer = L.geoJSON(item.geojson, {
                                style: (feature) => ({
                                    color: feature.properties.color || '#3388ff',
                                    weight: 3,
                                    opacity: 1,
                                    fillOpacity: 0.2
                                }),
                                pointToLayer: (feature, latlng) => {
                                    if (feature.properties.markerType === 'Circle' && feature.properties.radius) {
                                        return L.circle(latlng, {
                                            radius: feature.properties.radius * 1609.34, // Convert miles to meters
                                            color: feature.properties.color || '#3388ff'
                                        });
                                    }
                                    return L.marker(latlng);
                                }
                            }).addTo(map);

                            // Add labels if name exists
                            if (item.geojson.properties.name) {
                                L.marker(L.GeoJSON.coordsToLatLng(item.geojson.geometry.coordinates), {
                                    icon: L.divIcon({
                                        className: 'drawn-item-label',
                                        html: item.geojson.properties.name
                                    })
                                }).addTo(map);
                            }
                        }
                    });
                } catch (error) {
                    console.error('Error fetching drawn items:', error);
                }
            };

            fetchAndRenderDrawnItems();
            
            return () => {
                if (drawnItems) {
                    drawnItems.clearLayers();
                }
            };
        }
    }, [selectedIncident, drawnItems, map]);

    return null;
}

function MobileMapController({ isMobileMode }) {
    const map = useMap();

    useEffect(() => {
        if (isMobileMode) {
            // Mobile mode map settings
            map.setMinZoom(17);
            map.setMaxZoom(21);
            map.dragging.disable();
            
            // Force initial zoom level
            if (map.getZoom() < 17) {
                map.setZoom(17);
            }
        } else {
            // Desktop mode map settings
            map.setMinZoom(0);
            map.setMaxZoom(22);
            map.dragging.enable();
        }
    }, [isMobileMode, map]);

    return null;
}

const MapPage = ({ setSelectedIncident }) => {
    const position = [41.0340, -73.7629];
    const homePosition = position;
    const [showForm, setShowForm] = useState(false);
    const [incidentLocation, setIncidentLocation] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(null);
    const [incidents, setIncidents] = useState([]);
    const [activeIncidents, setActiveIncidents] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showCloseModal, setShowCloseModal] = useState(false);
    const [showEditMarkersModal, setShowEditMarkersModal] = useState(false);
    const [selectedIncident, setSelectedIncidentState] = useState(null); // <-- Define selectedIncident here
    const selectedIncidentRef = useRef(null);
    const [drawnItems, setDrawnItems] = useState(new L.FeatureGroup());
    const [markers, setMarkers] = useState([]);
    const [showNameColorForm, setShowNameColorForm] = useState(false);
    const [currentLayer, setCurrentLayer] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [showLayerModal, setShowLayerModal] = useState(false);
    const [csvFiles, setCSVFiles] = useState([]);
    const [selectedLayers, setSelectedLayers] = useState([]);
    const [layerData, setLayerData] = useState({});
    const [user, setUser] = useState({ name: '', avatar: '', permissions: {} });
    const [selectedMapType, setSelectedMapType] = useState('openStreetMap');
    const [showPoliceGPS, setShowPoliceGPS] = useState(() => {
        return hasPermission(user, PERMISSIONS.POLICE_GPS) || hasPermission(user, PERMISSIONS.ADMIN);
    });
    const [showFireGPS, setShowFireGPS] = useState(() => {
        return hasPermission(user, PERMISSIONS.FIRE_GPS) || hasPermission(user, PERMISSIONS.ADMIN);
    });
    const [showStarChase, setShowStarChase] = useState(false);
    const [showTooltips, setShowTooltips] = useState(true);
    const [currentZoom, setCurrentZoom] = useState(13);
    const [tooltipFontSize, setTooltipFontSize] = useState('9'); // Default to medium (9px)
    const [weatherEnabled, setWeatherEnabled] = useState(false);
    const [weatherLayerType, setWeatherLayerType] = useState('precipitation_new');
    const [weatherPreferences, setWeatherPreferences] = useState({
        enabled: false,
        layerType: 'temperature'
    });
    const [isLayerEditing, setIsLayerEditing] = useState(false);

    // Add new state for mobile mode
    const [isMobileMode, setIsMobileMode] = useState(false);
    
    // Add state for mobile tracking
    const [isTracking, setIsTracking] = useState(false);
    const [currentUserPosition, setCurrentUserPosition] = useState(null);
    const [currentHeading, setCurrentHeading] = useState(0);

    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const userData = JSON.parse(decodeURIComponent(query.get('user')));

        if (userData) {
            setUser({
                name: userData.userName,
                userId: userData.userId,
                avatar: userData.userAvatar,
                permissions: userData.permissions
            });
        }
    }, [location]);

    useEffect(() => {
        if (user && user.permissions) {
            setShowPoliceGPS(hasPermission(user, PERMISSIONS.POLICE_GPS) || hasPermission(user, PERMISSIONS.ADMIN));
            setShowFireGPS(hasPermission(user, PERMISSIONS.FIRE_GPS) || hasPermission(user, PERMISSIONS.ADMIN));
        }
    }, [user]);

    useEffect(() => {
        const fetchActiveIncidents = async () => {
            try {
                const incidentResponse = await fetch(`${API_URL}/api/incidents`);
                const incidentData = await incidentResponse.json();
                const activeIncidents = incidentData.filter(incident => incident.active);
                setActiveIncidents(activeIncidents);
            } catch (error) {
                console.error('Failed to load active incidents:', error);
            }
        };
        
        // Add cleanup and memory management
        let isMounted = true;
        const controller = new AbortController();
        
        fetchActiveIncidents();
        const intervalId = setInterval(fetchActiveIncidents, 5000);

        return () => {
            isMounted = false;
            controller.abort();
            clearInterval(intervalId);
        };
    }, []);

    const fetchMarkers = async (incidentId) => {
        try {
            if (!incidentId) {
                setMarkers([]);
                return;
            }

            const response = await fetch(`${API_URL}/api/drawn-items?incident_id=${incidentId}`, {
                credentials: 'include'
            });
            
            if (!response.ok) {
                throw new Error('Failed to fetch markers');
            }
            
            const markerData = await response.json();
            //console.log('Received marker data:', markerData);
            
            // Filter active markers and ensure they have valid geojson
            const validMarkers = markerData.filter(marker => 
                marker.active && 
                marker.geojson && 
                marker.geojson.geometry && 
                marker.geojson.geometry.coordinates
            );
            
            //console.log('Valid markers to render:', validMarkers);
            setMarkers(validMarkers);
        } catch (error) {
            console.error('Failed to load markers:', error);
            toast.error('Failed to load markers');
        }
    };

    useEffect(() => {
        if (selectedIncident && selectedIncident.incident_id) {
            // Initial fetch
            fetchMarkers(selectedIncident.incident_id);

            // Set up interval for polling
            const intervalId = setInterval(() => {
                fetchMarkers(selectedIncident.incident_id);
            }, 2500); // Check every second

            // Cleanup
            return () => {
                clearInterval(intervalId);
            };
        } else {
            setMarkers([]);
        }
    }, [selectedIncident]); // Dependency on selectedIncident

    // Define fetchCSVFiles inside the component
    const fetchCSVFiles = async () => {
        try {
            const response = await fetch(`${API_URL}/api/csv-files`, {
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setCSVFiles(data);
        } catch (error) {
            console.warn('CSV files not available:', error);
            setCSVFiles([]); // Set empty array as fallback
        }
    };

    // Add useEffect for CSV file fetching
    useEffect(() => {
        let intervalId = null; // Declare intervalId inside useEffect

        const fetchData = async () => {
            await fetchCSVFiles();
            intervalId = setInterval(fetchCSVFiles, 30000); // Store intervalId
        };

        fetchData();

        // Cleanup function
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []); // Empty dependency array means this runs once on mount

    useEffect(() => {
        const fetchLayerData = async () => {
            const dataPromises = selectedLayers.map(async (filename) => {
                try {
                    const response = await fetch(`${API_URL}/api/csv/data/${filename}`, {
                        credentials: 'include'
                    });
                    if (!response.ok) throw new Error(`Failed to fetch CSV data for ${filename}`);
                    const geoJSONData = await response.json();
                    return { filename, data: geoJSONData };
                } catch (error) {
                    console.error(`Error loading CSV data for ${filename}:`, error);
                    toast.error(`Failed to load data for ${filename}`);
                    return { filename, data: null };
                }
            });

            const layersData = await Promise.all(dataPromises);
            const newLayerData = {};
            layersData.forEach(({ filename, data }) => {
                if (data) {
                    newLayerData[filename] = data;
                }
            });
            setLayerData(newLayerData);
        };

        fetchLayerData();
    }, [selectedLayers]);

    useEffect(() => {
        const renderMarkersInBatches = () => {
            Object.entries(layerData).forEach(([filename, data]) => {
                let batchSize = 50; // Number of markers to add per batch
                let index = 0;

                const addNextBatch = () => {
                    const nextBatch = data.features.slice(index, index + batchSize);
                    nextBatch.forEach((feature, featureIndex) => {
                        renderGeoJSONLayer(feature, index + featureIndex, 'csv-marker-tooltip');
                    });
                    index += batchSize;

                    if (index < data.features.length) {
                        setTimeout(addNextBatch, 100); // Delay between batches (100ms)
                    }
                };

                addNextBatch();
            });
        };

        renderMarkersInBatches();
    }, [layerData]);

    useEffect(() => {
        selectedIncidentRef.current = selectedIncident;
    }, [selectedIncident]);

    useEffect(() => {
        const toolbar = document.querySelector('.leaflet-draw-toolbar');
        if (toolbar) {
            if (!selectedIncident) {
                toolbar.classList.add('no-selected-incident');
            } else {
                toolbar.classList.remove('no-selected-incident');
            }
        }
    }, [selectedIncident]);

    const handleFormSubmit = async (newIncidentData) => {
        if (!hasPermission(user, PERMISSIONS.MAKE_INCIDENTS)) {
            alert('You do not have permission to create incidents');
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/incidents`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newIncidentData)
            });

            if (!response.ok) {
                throw new Error('Failed to create incident');
            }

            const createdIncident = await response.json();

            const newMarker = {
                incident_id: createdIncident.incident_id,
                geojson: {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [createdIncident.location_long, createdIncident.location_lat]
                    },
                    properties: {
                        name: createdIncident.name,
                        color: '#3388ff'
                    }
                }
            };

            const radiusInMeters = createdIncident.radius * 1609.34;
            const circleGeoJSON = {
                type: "Feature",
                geometry: {
                    type: "Polygon",
                    coordinates: [Array.from({ length: 64 }, (_, i) => {
                        const angle = (i * 360) / 64;
                        const latitudeOffset = (radiusInMeters / 111320) * Math.cos(angle * (Math.PI / 180));
                        const longitudeOffset = (radiusInMeters / (111320 * Math.cos(createdIncident.location_lat * (Math.PI / 180)))) * Math.sin(angle * (Math.PI / 180));
                        return [createdIncident.location_long + longitudeOffset, createdIncident.location_lat + latitudeOffset];
                    })]
                },
                properties: {
                    name: `${createdIncident.name} `,
                    color: 'rgba(0, 0, 255, 0.2)'
                }
            };

            setMarkers((prevMarkers) => [...prevMarkers, newMarker, { incident_id: createdIncident.incident_id, geojson: circleGeoJSON, name: createdIncident.name, color: 'rgba(0, 0, 255, 0.1)' }]);

            const drawnItemData = {
                type: circleGeoJSON.geometry.type,
                coordinates: circleGeoJSON.geometry.coordinates,
                properties: circleGeoJSON.properties,
                incident_id: createdIncident.incident_id
            };
            console.log('Submitting drawn item data:', drawnItemData); 
            saveDrawnItem(drawnItemData);

            setSelectedIncidentState(createdIncident); // <-- Update state

            toast.success('Incident entered in database', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setShowForm(false);

        } catch (error) {
            console.error('Error creating incident:', error);
        }
    };

    const handleIncidentClick = (incident) => {
        setSelectedIncidentState(incident); // <-- Update state
        setSelectedIncident(incident); // <-- Call setSelectedIncident prop
    };

    const metersToFeet = (meters) => meters * 3.28084;

    const onCreated = (e) => {
        const { layerType, layer } = e;
        setCurrentLayer(layer);
        setShowNameColorForm(true);
    };

    const handleNameColorSubmit = (data) => {
        setShowNameColorForm(false);
        if (!selectedIncidentRef.current) {
            alert('Please select an active incident first.');
            return;
        }

        const { name, color } = data;

        if (currentLayer) {
            if (currentLayer.setStyle) {
                currentLayer.setStyle({ color });
            }
            currentLayer.bindTooltip(name, { permanent: true, direction: 'top', offset: [0, -20] });
        }

        let geojsonData;
        const layerType = currentLayer.feature ? currentLayer.feature.geometry.type : currentLayer instanceof L.Marker ? 'Point' : '';

        if (currentLayer instanceof L.Circle) {
            const circle = currentLayer;
            const latLng = circle.getLatLng();
            const radius = circle.getRadius();

            if (radius <= 10) {
                geojsonData = {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [latLng.lng, latLng.lat]
                    },
                    properties: {
                        name: name,
                        color: color,
                        radius: radius,
                        markerType: 'CircleMarker'
                    }
                };
            } else {
                const options = { steps: 64, units: 'meters' };
                const turfCircle = turf.circle([latLng.lng, latLng.lat], radius, options);
                turfCircle.properties = {
                    name: name,
                    color: color,
                    radius: radius,
                    markerType: 'ComplexCircle'
                };
                geojsonData = turfCircle;
            }
        } else {
            geojsonData = currentLayer.toGeoJSON();
            geojsonData.properties = { name, color, markerType: layerType };
        }

        const drawnItemData = {
            type: geojsonData.geometry.type,
            coordinates: geojsonData.geometry.coordinates,
            properties: geojsonData.properties,
            incident_id: selectedIncidentRef.current.incident_id
        };
        console.log('Submitting drawn item data:', drawnItemData); 
        saveDrawnItem(drawnItemData);
    };

    const saveDrawnItem = async (drawnItemData) => {
        try {
            if (!selectedIncidentRef.current) {
                toast.error('Please select an incident first');
                return;
            }

            // Ensure markerType is not empty
            if (!drawnItemData.properties.markerType) {
                drawnItemData.properties.markerType = drawnItemData.type;
            }

            console.log('Attempting to save drawn item:', drawnItemData);
            
            const response = await fetch(`${API_URL}/api/drawn-items`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    type: drawnItemData.type,
                    coordinates: drawnItemData.coordinates,
                    properties: {
                        name: drawnItemData.properties.name,
                        color: drawnItemData.properties.color,
                        markerType: drawnItemData.properties.markerType
                    },
                    incident_id: selectedIncidentRef.current.incident_id
                })
            });

            const responseData = await response.json();

            if (!response.ok) {
                console.error('Server error response:', responseData);
                throw new Error(responseData.error || 'Failed to save drawn item');
            }

            // Refresh markers after successful save
            await fetchMarkers(selectedIncidentRef.current.incident_id);
            
            console.log('Successfully saved drawn item:', responseData);
            return responseData;
        } catch (error) {
            console.error('Error saving drawn item:', error);
            toast.error(`Failed to save drawn item: ${error.message}`);
            throw error;
        }
    };

    const handleDeleteMarkers = async (selectedMarkerIds) => {
        try {
            if (!selectedIncidentRef.current) {
                toast.error('No incident selected');
                return;
            }

            // Make DELETE request for each drawn item
            const deletePromises = selectedMarkerIds.map(itemId =>
                fetch(`${API_URL}/api/drawn-items/${itemId}`, {
                    method: 'DELETE',
                    credentials: 'include'
                })
            );

            await Promise.all(deletePromises);

            // Refresh drawn items after deletion
            const response = await fetch(
                `${API_URL}/api/drawn-items?incident_id=${selectedIncidentRef.current.incident_id}`,
                { credentials: 'include' }
            );
            
            if (!response.ok) throw new Error('Failed to fetch updated items');
            
            const updatedItems = await response.json();
            setMarkers(updatedItems.filter(item => item.active));

            toast.success('Items deleted successfully');
        } catch (error) {
            console.error('Error deleting items:', error);
            toast.error('Failed to delete items');
        }
    };

    const handleCloseIncident = async (formData) => {
        try {
            if (!selectedIncidentRef.current) {
                toast.error('No incident selected');
                return;
            }

            const response = await fetch(`${API_URL}/api/incidents/close-incident`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    incident_id: selectedIncidentRef.current.incident_id,
                    disposition: formData.disposition,
                    notes: formData.notes,
                    email_requested: formData.emailRequested,
                    email_address: formData.emailAddress,
                    created_by_name: user.name,
                    created_by_userid: user.userId
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            
            if (data.success) {
                toast.success('Incident closed successfully');
                setShowCloseModal(false);
                
                // Refresh active incidents
                try {
                    const incidentResponse = await fetch(`${API_URL}/api/incidents`);
                    const incidentData = await incidentResponse.json();
                    const activeIncidents = incidentData.filter(incident => incident.active);
                    setActiveIncidents(activeIncidents);
                    
                    // Clear selected incident
                    setSelectedIncident(null);
                } catch (error) {
                    console.error('Failed to refresh incidents:', error);
                }
            } else {
                throw new Error(data.error || 'Failed to close incident');
            }
        } catch (error) {
            console.error('Error closing incident:', error);
            toast.error(`Failed to close incident: ${error.message}`);
        }
    };

    const renderGeoJSONLayer = (geojson, index, customClass = '') => {
        const { type, coordinates } = geojson.geometry;
        const { name, color, radius, markerType } = geojson.properties;
        const uniqueKey = `${name}-${index}`;

        if (!type) {
            console.error('Unsupported GeoJSON type or markerType:', type, markerType);
            return null;
        }

        const tooltipClass = customClass || 'special-tooltip';

        switch (type) {
            case 'Point':
                if (markerType === 'CircleMarker') {
                    return (
                        <CircleMarker
                            key={uniqueKey}
                            center={[coordinates[1], coordinates[0]]}
                            color={color || 'darkblue'}
                            fillColor={color || 'lightblue'}
                            radius={radius || 5}
                            className={customClass}
                        >
                            <Tooltip permanent className={tooltipClass}>{name}</Tooltip>
                        </CircleMarker>
                    );
                } else {
                    const latLng = L.latLng(coordinates[1], coordinates[0]);
                    const radiusInMeters = metersToFeet(radius || 5);
                    const turfCircle = turf.circle([latLng.lng, latLng.lat], radiusInMeters, { steps: 64, units: 'meters' });
                    const latlngs = turfCircle.geometry.coordinates[0].map(coord => L.latLng(coord[1], coord[0]));
                    return (
                        <Polygon
                            key={uniqueKey}
                            positions={latlngs}
                            color={color || 'darkblue'}
                            fillColor={color || 'lightblue'}
                            fillOpacity={0.4}
                            className={customClass}
                        >
                            <Tooltip permanent className={tooltipClass}>{name}</Tooltip>
                        </Polygon>
                    );
                }
            case 'Polygon':
                return (
                    <Polygon
                        key={uniqueKey}
                        positions={coordinates[0].map(coord => [coord[1], coord[0]])}
                        color={color || 'darkblue'}
                        fillColor={color || 'lightblue'}
                        fillOpacity={0.4}
                        className={customClass}
                    >
                        <Tooltip permanent className={tooltipClass}>{name}</Tooltip>
                    </Polygon>
                );
            case 'LineString':
                return (
                    <Polyline
                        key={uniqueKey}
                        positions={coordinates.map(coord => [coord[1], coord[0]])}
                        color={color || 'darkblue'}
                        className={customClass}
                    >
                        <Tooltip permanent className={tooltipClass}>{name}</Tooltip>
                    </Polyline>
                );
            default:
                console.error('Unsupported GeoJSON type or markerType:', type, markerType);
                return null;
        }
    };

    const handleLogout = async () => {
        try {
            // Clear local storage first
            localStorage.removeItem('user');
            localStorage.removeItem('authToken');
            
            // Call server logout endpoint with proper headers
            await fetch('/logout', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error('Logout request failed:', error);
        } finally {
            // Always redirect to login page, even if the logout request fails
            window.location.href = '/login';
            
            // If the above doesn't work, try forcing a full page reload to /login
            if (window.location.pathname !== '/login') {
                window.location.replace('/login');
            }
        }
    };

    // Add a ref to track the current map type
    const currentMapTypeRef = useRef(null);

    const getActiveMapUrl = useCallback(() => {
        const shouldUseGoogleMaps = currentZoom >= 19;
        const mapUrl = shouldUseGoogleMaps 
            ? `https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}&key=${googleApiKey}`
            : MAP_URLS[selectedMapType];

        if (currentMapTypeRef.current !== mapUrl) {
            currentMapTypeRef.current = mapUrl;
        }

        return mapUrl;
    }, [currentZoom, selectedMapType, googleApiKey]);

    const getMapAttribution = () => {
        if (currentZoom >= 19) {
            return '© Google';
        }
        return selectedMapType === 'openStreetMap'
            ? '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            : '© Google';
    };

    // Add console log to track selectedMapType changes
    useEffect(() => {
        console.log('selectedMapType changed to:', selectedMapType);
    }, [selectedMapType]);

    /*Add console log to track currentZoom changes
    useEffect(() => {
        console.log('currentZoom changed to:', currentZoom);
    }, [currentZoom]);
*/

    // Add this effect to load user preferences
    useEffect(() => {
        const fetchWeatherPreferences = async () => {
            try {
                const response = await fetch(`${API_URL}/api/weather/preferences`, {
                    credentials: 'include'
                });
                
                if (!response.ok) {
                    console.warn('Weather preferences not found, using defaults');
                    return;
                }
                
                const data = await response.json();
                setWeatherPreferences(data);
            } catch (error) {
                console.warn('Error loading weather preferences, using defaults:', error);
            }
        };

        fetchWeatherPreferences();
    }, []);

    // Add this handler
    const handleWeatherToggle = (enabled) => {
        console.log('Weather toggle:', enabled);
        setWeatherEnabled(enabled);
    };

    const handleWeatherLayerChange = (type) => {
        console.log('Weather layer change:', type);
        setWeatherLayerType(type);
    };

    // Layer control modal state
    const [isLayerControlOpen, setLayerControlOpen] = useState(false);

    // Font size options
    const fontSizeOptions = ['8', '10', '12', '14', '16', '18', '20'];

    // Handlers
    const handleMapTypeChange = (type) => {
        setSelectedMapType(type);
    };

    const handleLayerToggle = (layer) => {
        setSelectedLayers(prev => {
            if (prev.includes(layer)) {
                return prev.filter(l => l !== layer);
            } else {
                return [...prev, layer];
            }
        });
    };

    // Add this effect to fetch markers when selected incident changes
    useEffect(() => {
        const fetchMarkers = async () => {
            if (selectedIncidentRef.current) {
                try {
                    const response = await fetch(
                        `${API_URL}/api/drawn-items?incident_id=${selectedIncidentRef.current.incident_id}`,
                        { credentials: 'include' }
                    );
                    
                    if (!response.ok) throw new Error('Failed to fetch markers');
                    
                    const data = await response.json();
                    setMarkers(data.filter(item => item.active));
                } catch (error) {
                    console.error('Error fetching markers:', error);
                    setMarkers([]);
                }
            }
        };

        fetchMarkers();
    }, [selectedIncidentRef.current]);

    useEffect(() => {
        const handleRefresh = async () => {
            if (selectedIncidentRef.current) {
                try {
                    const response = await fetch(
                        `${API_URL}/api/drawn-items?incident_id=${selectedIncidentRef.current.incident_id}`,
                        { credentials: 'include' }
                    );
                    
                    if (!response.ok) throw new Error('Failed to fetch updated items');
                    
                    const updatedItems = await response.json();
                    setMarkers(updatedItems.filter(item => item.active));
                } catch (error) {
                    console.error('Error refreshing markers:', error);
                }
            }
        };

        window.addEventListener('refreshMarkers', handleRefresh);
        return () => window.removeEventListener('refreshMarkers', handleRefresh);
    }, []);

    useEffect(() => {
        if (isMobileMode && isTracking) {
            const watchOptions = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            };

            const watchId = navigator.geolocation.watchPosition(
                (position) => {
                    const { latitude, longitude, heading } = position.coords;
                    setCurrentUserPosition({ lat: latitude, lng: longitude });
                    setCurrentHeading(heading || 0);
                    
                    // Send location update to server
                    fetch(`${API_URL}/api/mobile-location`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            displayName: user.name,
                            userId: user.userId,
                            latitude,
                            longitude,
                            heading: heading || 0
                        }),
                        credentials: 'include'
                    });
                },
                (error) => console.error('GPS Error:', error),
                watchOptions
            );

            return () => navigator.geolocation.clearWatch(watchId);
        }
    }, [isMobileMode, isTracking, user]);

    const getVisibleLayers = () => {
        if (isMobileMode) {
            return {
                showPoliceGPS: true,
                showFireGPS: true,
                showTooltips: true,
                showStarChase: false,
                showWeather: false
            };
        }
        return {
            showPoliceGPS,
            showFireGPS,
            showTooltips,
            showStarChase,
            weatherEnabled
        };
    };

    const handleMobileToggle = () => {
        if (!isMobileMode) {
            // Request location permission before enabling mobile mode
            navigator.geolocation.getCurrentPosition(
                () => {
                    setIsMobileMode(true);
                },
                (error) => {
                    console.error('Location permission denied:', error);
                    alert('Location permission is required for mobile mode');
                }
            );
        } else {
            setIsMobileMode(false);
        }
    };

    // Add these handler functions before the return statement
    const handleDrawCreated = (e) => {
        const layer = e.layer;
        const drawnItemType = e.layerType;
        
        // Store the drawn item properties
        const drawnItem = {
            type: drawnItemType,
            coordinates: layer.getLatLngs ? layer.getLatLngs() : layer.getLatLng(),
            properties: {
                color: layer.options.color,
                weight: layer.options.weight,
                opacity: layer.options.opacity,
                fillOpacity: layer.options.fillOpacity
            }
        };

        if (drawnItemType === 'circle') {
            drawnItem.radius = layer.getRadius();
        }

        console.log('Draw created:', drawnItem);
    };

    const handleDrawDeleted = (e) => {
        const layers = e.layers;
        layers.eachLayer((layer) => {
            console.log('Layer deleted:', layer);
        });
    };

    return (
        <div className={`map-container ${isMobileMode ? 'mobile-mode' : ''}`}>
            {!isMobileMode ? (
                // Desktop mode UI
                <div className="overlay-top">
                    <div className="user-info">
                        {user.avatar && <img src={user.avatar} alt="User Avatar" className="user-avatar" />}
                        <span className="user-name" onClick={handleLogout}>Welcome, {user.name}</span>
                    </div>
                </div>
            ) : null}
            
            <MapContainer center={position} zoom={13} maxZoom={20} style={{ height: '100vh', width: '100%' }}>
                {!isMobileMode && <DrawingTools onCreated={handleDrawCreated} onDeleted={handleDrawDeleted} />}
                <MobileMapController isMobileMode={isMobileMode} />
                {isMobileMode && (
                    <MobileMode
                        user={user}
                        onExit={() => setIsMobileMode(false)}
                        currentPosition={currentUserPosition}
                        heading={currentHeading}
                        homePosition={position}
                    />
                )}
                
                <TileLayer
                    key={`tile-layer-${currentZoom >= 19 ? 'google' : selectedMapType}`}
                    url={getActiveMapUrl()}
                    attribution={getMapAttribution()}
                    maxZoom={20}
                    tileSize={256}
                    zoomOffset={0}
                />
                <MapClickHandler setIncidentLocation={setIncidentLocation} setMarkerPosition={setMarkerPosition} />
                <ZoomToIncident incident={selectedIncident} />
                <IncidentLayerManager 
                    selectedIncident={selectedIncident}
                    activeIncidents={activeIncidents}
                    drawnItems={drawnItems}
                    isEditing={isLayerEditing}
                />
                <NameColorForm
                    show={showNameColorForm}
                    onClose={() => setShowNameColorForm(false)}
                    onSubmit={handleNameColorSubmit}
                />
                {incidents.map((incident, incidentIndex) => (
                    <CircleMarker
                        key={`incident-${incident.incident_id}-${incidentIndex}`}
                        center={[incident.location_lat, incident.location_long]}
                        color="darkblue"
                        fillColor="lightblue"
                        radius={10}
                        fillOpacity={0.5}
                    >
                        <Tooltip permanent direction="top" offset={[0, -20]}>{incident.name}</Tooltip>
                    </CircleMarker>
                ))}
                {markerPosition && (
                    <CircleMarker
                        key={`marker-${markerPosition.lat}-${markerPosition.lng}`}
                        center={markerPosition}
                        color="darkblue"
                        fillColor="lightblue"
                        radius={10}
                        fillOpacity={0.5}
                    >
                        <Tooltip permanent direction="top" offset={[0, -20]}>
                            Selected Location: {markerPosition.lat.toFixed(3)}, {markerPosition.lng.toFixed(3)}
                        </Tooltip>
                    </CircleMarker>
                )}
                {markers.map((marker, markerIndex) => {
                    const geojson = marker.geojson;
                    if (geojson && geojson.geometry) {
                        return renderGeoJSONLayer(geojson, markerIndex);
                    } else {
                        console.error('Invalid marker position:', marker);
                        return null;
                    }
                })}
                {(showPoliceGPS || showFireGPS) && (
                    <VehicleLayer 
                        setVehicles={setVehicles} 
                        showPoliceGPS={showPoliceGPS}
                        showFireGPS={showFireGPS}
                        showTooltips={showTooltips}
                        tooltipFontSize={tooltipFontSize}
                    />
                )}
                {(showPoliceGPS || showFireGPS) && vehicles.length > 0 && activeIncidents.length > 0 && (
                    <VehicleTracker 
                        vehicles={vehicles.filter(v => 
                            (showPoliceGPS && v.type === 'police') || 
                            (showFireGPS && (v.type === 'fire' || v.type === 'ems'))
                        )} 
                        incidents={activeIncidents} 
                    />
                )}
                <VehicleSearchHandler vehicles={vehicles} onVehicleSelect={() => { }} />
                {Object.entries(layerData).map(([filename, layerDataItem], index) => (
                    <React.Fragment key={index}>
                        {layerDataItem.features.map((feature, featureIndex) => 
                            renderGeoJSONLayer(feature, featureIndex, 'csv-marker')
                    )}
                </React.Fragment>
                ))}
                <Toolbar
                    homePosition={position}
                    setShowLayerModal={setShowLayerModal}
                    setShowForm={setShowForm}
                    setShowEditMarkersModal={setShowEditMarkersModal}
                    setShowCloseModal={setShowCloseModal}
                    user={user}
                    isMobileMode={isMobileMode}
                    onMobileToggle={handleMobileToggle}
                />
                {showStarChase && hasPermission(user, PERMISSIONS.STARCHASE) && (
                    <StarChaseLayer />
                )}
                <WeatherOverlay 
                    enabled={weatherEnabled} 
                    layerType={weatherLayerType}
                />
                
                <CADAlerts />
            </MapContainer>

            {showForm && <StyledIncidentForm onClose={() => setShowForm(false)} onSubmit={handleFormSubmit} location={incidentLocation} />}
            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
                <Modal.Body className="modal-success">{successMessage}</Modal.Body>
            </Modal>
            <CloseIncidentModal
                show={showCloseModal}
                onClose={() => setShowCloseModal(false)}
                onSubmit={handleCloseIncident}
                incidentId={selectedIncidentRef.current ? selectedIncidentRef.current.incident_id : null}
            />
            <EditMarkersModal
                show={showEditMarkersModal}
                onClose={() => setShowEditMarkersModal(false)}
                markers={markers}
                setLayerEditing={setIsLayerEditing}
            />
            <LayerControlModal
                isOpen={isLayerControlOpen}
                onClose={() => setLayerControlOpen(false)}
                selectedMapType={selectedMapType}
                onMapTypeChange={handleMapTypeChange}
                user={user}
                showPoliceGPS={showPoliceGPS}
                setShowPoliceGPS={setShowPoliceGPS}
                showFireGPS={showFireGPS}
                setShowFireGPS={setShowFireGPS}
                showStarChase={showStarChase}
                setShowStarChase={setShowStarChase}
                csvFiles={csvFiles}
                selectedLayers={selectedLayers}
                handleLayerToggle={handleLayerToggle}
                showTooltips={showTooltips}
                setShowTooltips={setShowTooltips}
                tooltipFontSize={tooltipFontSize}
                setTooltipFontSize={setTooltipFontSize}
                fontSizeOptions={fontSizeOptions}
                weatherEnabled={weatherEnabled}
                onWeatherToggle={handleWeatherToggle}
                weatherLayerType={weatherLayerType}
                onWeatherLayerChange={handleWeatherLayerChange}
            />
            <WeatherAlerts />
        </div>
    );
};

export default MapPage;
