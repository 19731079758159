import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useMap } from 'react-leaflet';
import { icons } from './utils';
import L from 'leaflet';
import 'leaflet-rotatedmarker';
import { PERMISSIONS, hasPermission } from '../utils/permissions';
import axios from 'axios';
import io from 'socket.io-client';

const determineIconType = (displayName, isFireVehicle = false) => {
    if (isFireVehicle) {
        if (displayName.includes('Tanker')) {
            return 'tanker';
        } else if (displayName.includes('Ladder')) {
            return 'ladder';
        } else if (displayName.includes('EMS', 'BLS', 'ALS')) {
            return 'ambulance';
        } else {
            return 'engine';
        }
    }

    if (displayName.includes('Det') || 
        displayName.includes('Detective') || 
        displayName.includes('Inv') || 
        displayName.includes('Investigator') || 
        displayName.includes('Narco') || 
        displayName.includes('SIU') || 
        displayName.includes('FBI') || 
        displayName.includes('Auto Larceny')) {
        return 'detcar';
    } else if (displayName.includes('K9')) {
        return 'k9';
    } else if (displayName.includes('Marine')) {
        return 'boat';
    } else if (displayName.includes('Aviation')) {
        return 'helicopter';
    } else if (displayName.includes('47 PCT CO')) {
        return 'rav4';
    } else if (displayName.includes('ESU')) {
        return 'esu';
    } else if (displayName.includes('EMS')) {
        return 'ambulance';
    } else if (displayName.includes('NYPD')) {
        return 'nypd';
    } else if (displayName.includes('WCPD')) {
        return 'wcpd';
    } else if (displayName.includes('NYSP')) {
        return 'nysp';
    } else if (displayName.includes('CTSP') || 
              displayName.includes('Greenwich') || 
              displayName.includes('Stamford')) {
        return 'ctsp';
    } else {
        return 'policeCar';
    }
};

const determineTooltipClass = (displayName, isFireVehicle) => {
    if (isFireVehicle) {
        if (displayName.includes('EMS') || displayName.includes('BLS') || displayName.includes('ALS') || displayName.includes('Medic')) {
            return 'custom-tooltip ems-tooltip';
        }
        return 'custom-tooltip fire-tooltip';
    }
    if (displayName.includes('EMS') || displayName.includes('BLS') || displayName.includes('ALS') || displayName.includes('Medic')) {
        return 'custom-tooltip ems-tooltip';
    }
    return 'custom-tooltip police-tooltip';
};

const VehicleLayer = ({ setVehicles, showPoliceGPS, showFireGPS, showMobileGPS, showTooltips, tooltipFontSize }) => {
    const map = useMap();
    const [user, setUser] = useState({ permissions: {} });
    const [policeVehicles, setPoliceVehicles] = useState([]);
    const [fireVehicles, setFireVehicles] = useState([]);
    const [mobileVehicles, setMobileVehicles] = useState([]);
    const [followedVehicle, setFollowedVehicle] = useState(null);
    const markersRef = useRef({});
    const sourcesRef = useRef({ police: null, fire: null });
    const socketRef = useRef(null);
    const eventListenersRef = useRef({});
    const previousPositionRef = useRef(null);
    const omsRef = useRef(null);
    const prevZoomRef = useRef(null);
    const tooltipStateRef = useRef(new Set());

    // Parse userData once and memoize it
    const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);
    const userData = useMemo(() => {
        const userParam = urlParams.get('user');
        if (userParam) {
            return JSON.parse(decodeURIComponent(userParam));
        }
        return {};
    }, [urlParams]);

    useEffect(() => {
        const fetchUserPermissions = async () => {
            try {
                const response = await axios.get('https://devmerlin.westchesterrtc.com/api/user/permissions', {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-User-Email': userData.userEmail,
                    },
                });

                setUser((prevUser) => {
                    const newUser = {
                        ...userData,
                        permissions: response.data.permissions,
                    };
                    // Only update if different
                    if (JSON.stringify(prevUser) !== JSON.stringify(newUser)) {
                        return newUser;
                    } else {
                        return prevUser;
                    }
                });
            } catch (error) {
                console.error('Failed to fetch user permissions:', error);
                setUser({ permissions: {} });
            }
        };

        fetchUserPermissions();
    }, [userData]);
    

    const connectSSE = useCallback((type) => {
        try {
            if (sourcesRef.current[type]) {
                if (eventListenersRef.current[type]) {
                    sourcesRef.current[type].removeEventListener('message', eventListenersRef.current[type].onMessage);
                    sourcesRef.current[type].removeEventListener('error', eventListenersRef.current[type].onError);
                }
                sourcesRef.current[type].close();
                sourcesRef.current[type] = null;
                eventListenersRef.current[type] = null;
            }

            if (
                (type === 'police' && !hasPermission(user, PERMISSIONS.POLICE_GPS) && !hasPermission(user, PERMISSIONS.ADMIN)) ||
                (type === 'fire' && !hasPermission(user, PERMISSIONS.FIRE_GPS) && !hasPermission(user, PERMISSIONS.ADMIN))
            ) {
                return;
            }

            const endpoint = type === 'police' ? 'vehicles' : 'fireVehicles';

            sourcesRef.current[type] = new EventSource(
                `https://devmerlin.westchesterrtc.com/sse/${endpoint}?userEmail=${encodeURIComponent(userData.userEmail)}`,
                { withCredentials: true }
            );

            const onMessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    if (data && !data.error && data.type === 'FeatureCollection' && Array.isArray(data.features)) {
                        const newVehicles = data.features
                            .map((feature) => {
                                if (!feature.geometry?.coordinates || !feature.properties?.displayName) {
                                    return null;
                                }
                                return {
                                    ...feature.properties,
                                    latitude: feature.geometry.coordinates[1],
                                    longitude: feature.geometry.coordinates[0],
                                    iconType: determineIconType(feature.properties.displayName, type === 'fire'),
                                    isFireVehicle: type === 'fire',
                                };
                            })
                            .filter(Boolean);

                        if (type === 'police') {
                            setPoliceVehicles(newVehicles);
                        } else {
                            setFireVehicles(newVehicles);
                        }
                    }
                } catch (error) {
                    console.error(`Error processing ${type} SSE data:`, error);
                }
            };

            const onError = (error) => {
                console.error(`${type} SSE connection error:`, error);
            };

            if (!eventListenersRef.current[type]) {
                eventListenersRef.current[type] = {};
            }
            eventListenersRef.current[type].onMessage = onMessage;
            eventListenersRef.current[type].onError = onError;

            sourcesRef.current[type].addEventListener('message', onMessage);
            sourcesRef.current[type].addEventListener('error', onError);
        } catch (error) {
            console.error(`Error in connectSSE for ${type}:`, error);
        }
    }, [user, userData.userEmail]);

    const cleanupSSEConnection = useCallback((type) => {
        if (sourcesRef.current[type]) {
            try {
                if (eventListenersRef.current[type]) {
                    sourcesRef.current[type].removeEventListener('message', eventListenersRef.current[type].onMessage);
                    sourcesRef.current[type].removeEventListener('error', eventListenersRef.current[type].onError);
                    eventListenersRef.current[type] = null;
                }
                sourcesRef.current[type].close();
                sourcesRef.current[type] = null;
            } catch (error) {
                console.error(`Error cleaning up ${type} SSE connection:`, error);
            }
        }
    }, []);

    useEffect(() => {
        if (user.permissions && Object.keys(user.permissions).length > 0) {
            const connectToSSE = () => {
                if (showPoliceGPS) {
                    connectSSE('police');
                } else {
                    // Cleanup police SSE connection
                    cleanupSSEConnection('police');
                    setPoliceVehicles([]);
                }

                if (showFireGPS) {
                    connectSSE('fire');
                } else {
                    // Cleanup fire SSE connection
                    cleanupSSEConnection('fire');
                    setFireVehicles([]);
                }
            };

            connectToSSE();

            return () => {
                // Cleanup SSE connections
                cleanupSSEConnection('police');
                cleanupSSEConnection('fire');
            };
        }
    }, [user.permissions, showPoliceGPS, showFireGPS, connectSSE, cleanupSSEConnection]);

    const handleMarkerOverlap = useCallback((markers) => {
        markers.forEach(marker => {
            const tooltip = marker.getTooltip();
            if (tooltip) {
                tooltip.options.permanent = true;
                tooltip.options.opacity = 1;
                
                const tooltipElement = tooltip.getElement();
                if (tooltipElement) {
                    tooltipElement.style.cssText = `
                        font-size: ${tooltipFontSize}px !important;
                        padding: 2px 4px !important;
                        white-space: nowrap !important;
                        width: auto !important;
                        opacity: 1 !important;
                        display: block !important;
                        visibility: visible !important;
                    `;
                    marker.openTooltip();
                }
            }
        });
    }, [tooltipFontSize]);

    useEffect(() => {
        if (!followedVehicle) return;

        const allVehicles = [...policeVehicles, ...fireVehicles];
        const vehicle = allVehicles.find((v) => v.displayName === followedVehicle);

        if (vehicle) {
            const newPosition = [vehicle.latitude, vehicle.longitude];

            if (
                !previousPositionRef.current ||
                previousPositionRef.current[0] !== newPosition[0] ||
                previousPositionRef.current[1] !== newPosition[1]
            ) {
                map.panTo(newPosition, {
                    animate: true,
                    duration: 0.5,
                    easeLinearity: 0.5,
                });

                previousPositionRef.current = newPosition;
            }
        }
    }, [policeVehicles, fireVehicles, followedVehicle, map]);

    useEffect(() => {
        // Force update of all markers when font size changes
        const rafId = requestAnimationFrame(() => {
            const allMarkers = Object.values(markersRef.current);
            handleMarkerOverlap(allMarkers);
        });

        return () => {
            cancelAnimationFrame(rafId);
        };
    }, [tooltipFontSize, handleMarkerOverlap]);

    useEffect(() => {
        if (!map) return;

        const handleZoom = debounce(() => {
            requestAnimationFrame(() => {
                const allMarkers = Object.values(markersRef.current);
                handleMarkerOverlap(allMarkers);
            });
        }, 150);

        map.on('zoomend', handleZoom);
        
        const initialZoom = map.getZoom();
        prevZoomRef.current = initialZoom;
        handleZoom();
        
        return () => {
            map.off('zoomend', handleZoom);
            handleZoom.cancel();
        };
    }, [map, handleMarkerOverlap]);

    const debounce = (func, wait) => {
        let timeout;
        let lastArgs;
        let lastThis;
        let result;
        let timerId;

        const later = () => {
            timerId = null;
            result = func.apply(lastThis, lastArgs);
        };

        const debounced = function(...args) {
            lastArgs = args;
            lastThis = this;

            clearTimeout(timerId);
            timerId = setTimeout(later, wait);

            return result;
        };

        debounced.cancel = () => {
            clearTimeout(timerId);
            timerId = null;
        };

        return debounced;
    };

    // Define icons at the component level
    const icons = {
        policeCar: L.icon({
            iconUrl: '/images/icons/policecar/policeCar0.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        }),
        detcar: L.icon({
            iconUrl: '/images/icons/detcar.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        }),
        k9: L.icon({
            iconUrl: '/images/icons/k9/k90.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        }),
        boat: L.icon({
            iconUrl: '/images/icons/marine/boat0.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        }),
        helicopter: L.icon({
            iconUrl: '/images/icons/helicopter/helicopter0.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        }),
        rav4: L.icon({
            iconUrl: '/images/icons/rav4.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        }),
        esu: L.icon({
            iconUrl: '/images/icons/esu/esu0.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        }),
        ambulance: L.icon({
            iconUrl: '/images/icons/ambulance.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        }),
        engine: L.icon({
            iconUrl: '/images/icons/engine.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        }),
        ladder: L.icon({
            iconUrl: '/images/icons/ladder.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        }),
        tanker: L.icon({
            iconUrl: '/images/icons/tanker.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        }),
        nypd: L.icon({
            iconUrl: '/images/icons/nypdcar.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        }),
        wcpd: L.icon({
            iconUrl: '/images/icons/wcpd.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        }),
        nysp: L.icon({
            iconUrl: '/images/icons/nyspcar.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        }),
        ctsp: L.icon({
            iconUrl: '/images/icons/ctsp.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16]
        })
    };

    const getIconForDevice = (vehicle) => {
        const iconType = determineIconType(vehicle.displayName, vehicle.isFireVehicle);
        return icons[iconType] || icons.policeCar;
    };

    // Define handleMarkerClick as a regular function
    function handleMarkerClick(marker, displayName, latlng) {
        console.log('Click handler - Current following:', followedVehicle, 'Clicked:', displayName);
        
        if (followedVehicle && followedVehicle === displayName) {
            console.log('Stopping following:', displayName);
            // Clicking the currently followed vehicle - stop following
            setFollowedVehicle(null);
            
            // Update tooltip
            const currentMarker = markersRef.current[displayName];
            if (currentMarker && currentMarker.getTooltip()) {
                currentMarker.getTooltip().setContent(displayName);
            }
            
            // Re-enable map controls
            if (map) {
                map.dragging.enable();
                map.keyboard.enable();
            }
            
            // Reset previous position
            previousPositionRef.current = null;
            
        } else {
            console.log('Starting to follow:', displayName);
            // Start following a new vehicle
            setFollowedVehicle(displayName);

            // Update all tooltips
            Object.keys(markersRef.current).forEach((name) => {
                const m = markersRef.current[name];
                if (m && m.getTooltip()) {
                    m.getTooltip().setContent(name === displayName ? `${name} (Following)` : name);
                }
            });

            // Pan to the vehicle
            map.panTo(latlng, {
                animate: true,
                duration: 0.5,
                easeLinearity: 0.5,
            });
            previousPositionRef.current = latlng;

            // Only disable dragging while following
            map.dragging.disable();
            map.keyboard.disable();
        }
    }

    const updateMarkers = useCallback(() => {
        const allVehicles = [...policeVehicles, ...fireVehicles, ...mobileVehicles];
        
        allVehicles.forEach(vehicle => {
            const { displayName, latitude, longitude, heading } = vehicle;
            const latlng = [latitude, longitude];
            let marker = markersRef.current[displayName];
            
            try {
                if (marker && marker._map) {
                    marker.setLatLng(latlng);
                    marker.setIcon(getIconForDevice(vehicle));
                    marker.setRotationAngle(heading || 0);
                    
                    // Re-bind click handler
                    marker.off('click');
                    marker.on('click', () => handleMarkerClick(marker, displayName, latlng));
                    
                    if (showTooltips) {
                        if (!marker.getTooltip()) {
                            marker.bindTooltip(displayName, {
                                permanent: true,
                                direction: 'top',
                                className: determineTooltipClass(displayName, vehicle.isFireVehicle),
                            });
                            // Update the tooltip font size immediately after creation
                            const tooltipElement = marker.getTooltip().getElement();
                            if (tooltipElement) {
                                tooltipElement.style.cssText = `
                                    font-size: ${tooltipFontSize}px !important;
                                    padding: 2px 4px !important;
                                    white-space: nowrap !important;
                                    width: auto !important;
                                    opacity: 1 !important;
                                    display: block !important;
                                    visibility: visible !important;
                                `;
                            }
                        }
                    } else {
                        if (marker.getTooltip()) {
                            marker.unbindTooltip();
                        }
                    }
                } else {
                    if (marker) {
                        map.removeLayer(marker);
                    }
                    
                    marker = L.marker(latlng, {
                        icon: getIconForDevice(vehicle),
                        rotationAngle: heading || 0,
                        rotationOrigin: 'center center'
                    }).addTo(map);

                    // Bind click handler
                    marker.on('click', () => handleMarkerClick(marker, displayName, latlng));

                    if (showTooltips) {
                        marker.bindTooltip(displayName, {
                            permanent: true,
                            direction: 'top',
                            className: determineTooltipClass(displayName, vehicle.isFireVehicle),
                        });
                        
                        if (followedVehicle === displayName) {
                            marker.getTooltip().setContent(`${displayName} (Following)`);
                        }
                    }
                    
                    markersRef.current[displayName] = marker;
                }
            } catch (error) {
                console.error('Error updating/creating marker:', error);
            }
        });

        // Clean up removed markers
        Object.keys(markersRef.current).forEach(id => {
            if (!allVehicles.some(vehicle => vehicle.displayName === id)) {
                map.removeLayer(markersRef.current[id]);
                delete markersRef.current[id];
            }
        });
    }, [policeVehicles, fireVehicles, mobileVehicles, map, showTooltips, tooltipFontSize, followedVehicle]);

    useEffect(() => {
        if (map && (policeVehicles.length > 0 || fireVehicles.length > 0 || mobileVehicles.length > 0)) {
            updateMarkers();
        }
    }, [map, policeVehicles, fireVehicles, mobileVehicles, updateMarkers]);

    useEffect(() => {
        const allVehicles = [...policeVehicles, ...fireVehicles, ...mobileVehicles];
        setVehicles({
            police: policeVehicles,
            fire: fireVehicles,
            mobile: mobileVehicles,
            all: allVehicles,
        });
    }, [policeVehicles, fireVehicles, mobileVehicles, setVehicles]);

    const resetFollowing = () => {
        if (followedVehicle) {
            const marker = markersRef.current[followedVehicle];
            if (marker) {
                marker.getTooltip().setContent(followedVehicle);
            }
            setFollowedVehicle(null);
            map.dragging.enable();
        }
    };

    useEffect(() => {
        if (map) {
            map.resetVehicleFollowing = resetFollowing;
        }
        return () => {
            if (map) {
                delete map.resetVehicleFollowing;
            }
        };
    }, [map, followedVehicle]);

    const createMarker = useCallback((displayName, latlng, icon, heading, isFireVehicle) => {
        if (markersRef.current[displayName]?.tooltip) {
            markersRef.current[displayName].tooltip.dispose();
        }
        
        const marker = L.marker(latlng, {
            icon: icon,
            rotationAngle: heading,
            rotationOrigin: 'center center',
            zIndexOffset: 1000,
        });
        
        if (showTooltips) {
            marker.bindTooltip(displayName, {
                permanent: true,
                direction: 'top',
                className: determineTooltipClass(displayName, isFireVehicle),
                opacity: 0.9
            });
        }
        
        return marker;
    }, [showTooltips]);

    useEffect(() => {
        return () => {
            Object.keys(markersRef.current).forEach((displayName) => {
                const marker = markersRef.current[displayName];
                if (marker && marker._map) {
                    marker.off('click');
                    marker.unbindTooltip();
                    map.removeLayer(marker);
                }
            });
            markersRef.current = {};

            cleanupSSEConnection('police');
            cleanupSSEConnection('fire');

            setFollowedVehicle(null);
            previousPositionRef.current = null;
            prevZoomRef.current = null;
            tooltipStateRef.current = new Set();

            if (map) {
                map.dragging.enable();
                map.touchZoom.enable();
                map.doubleClickZoom.enable();
                map.scrollWheelZoom.enable();
                map.boxZoom.enable();
                map.keyboard.enable();
            }
        };
    }, [map, cleanupSSEConnection]);

    // Add mobile socket connection
    useEffect(() => {
        if (!showMobileGPS) {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
            setMobileVehicles([]);
            return;
        }

        socketRef.current = io('https://devmerlin.westchesterrtc.com', {
            withCredentials: true,
            transports: ['websocket', 'polling']
        });

        socketRef.current.on('mobile-locations', (data) => {
            if (!data.features) return;
            
            // Convert GeoJSON to vehicle format
            const vehicles = data.features.map(feature => ({
                displayName: feature.properties.displayName,
                latitude: feature.geometry.coordinates[1],
                longitude: feature.geometry.coordinates[0],
                heading: feature.properties.heading || 0,
                isPoliceVehicle: true // Treat as police vehicle for styling
            }));

            setMobileVehicles(vehicles);
        });

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, [showMobileGPS]);

    return null;
};

export default VehicleLayer;
