import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './StyledIncidentForm.css';

const StyledIncidentForm = ({ onClose, onSubmit, location }) => {
    const [formData, setFormData] = useState({
        name: '',
        type: '',
        location_lat: '',
        location_long: '',
        incident_commander: '',
        staging_manager: '',
        communications: '',
        notes: '',
        radius: 0.5
    });

    const [incidentTypes, setIncidentTypes] = useState([]);
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    
    // State to store the extracted user info
    const [userInfo, setUserInfo] = useState({
        userName: 'Unknown',
        userId: 'Unknown'
    });

    useEffect(() => {
        // Extract user information from the URL
        const params = new URLSearchParams(window.location.search);
        const user = params.get('user');
        console.log('Raw user data from URL:', user);
        if (user) {
            try {
                const decodedUser = JSON.parse(decodeURIComponent(user));
                console.log('Decoded user data:', decodedUser); 
                setUserInfo({
                    userName: decodedUser.userName || 'Unknown',
                    userId: decodedUser.userId || 'Unknown'
                });
            } catch (error) {
                console.error('Failed to parse user data from URL:', error);
            }
        }

        const API_URL = process.env.REACT_APP_API_URL || 'https://devmerlin.westchesterrtc.com';
        
        const fetchIncidentTypes = async () => {
            try {
                const response = await fetch(`${API_URL}/api/admin/database/incidentTypes`, {
                    credentials: 'include',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setIncidentTypes(data);
                console.log('Fetched incident types:', data);
            } catch (error) {
                console.error('Failed to fetch incident types:', error);
            }
        };

        fetchIncidentTypes();

        const now = new Date();
        setDate(now.toISOString().split('T')[0]);
        setTime(now.toTimeString().split(' ')[0].slice(0, 8));
    }, []);

    // Update form when location changes
    useEffect(() => {
        if (location) {
            setFormData(prev => ({
                ...prev,
                location_lat: location.lat,
                location_long: location.lng
            }));
        }
    }, [location]);

    const handleSubmit = () => {
        const submitData = {
            ...formData,
            location_lat: location ? location.lat : formData.location_lat,
            location_long: location ? location.lng : formData.location_long
        };
        onSubmit(submitData);
    };

    return (
        <Modal 
            show={true} 
            onHide={onClose} 
            centered
            className="incident-form-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>New Incident</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.name}
                            onChange={(e) => setFormData({...formData, name: e.target.value})}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formType" className="mt-3">
                        <Form.Label>Type</Form.Label>
                        <Form.Control
                            as="select"
                            value={formData.type}
                            onChange={(e) => setFormData({...formData, type: e.target.value})}
                            required
                        >
                            <option value="">Select Type</option>
                            {incidentTypes.map((incidentType) => (
                                <option key={incidentType.id} value={incidentType.name}>
                                    {incidentType.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formLocation" className="mt-3">
                        <Form.Label>Location (Click on Map)</Form.Label>
                        <Form.Control
                            type="text"
                            value={location ? `${location.lat.toFixed(6)}°, ${location.lng.toFixed(6)}°` : 'Click location on map to set coordinates'}
                            readOnly
                        />
                    </Form.Group>
                    <Form.Group controlId="formIncidentCommander" className="mt-3">
                        <Form.Label>Incident Commander</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.incident_commander}
                            onChange={(e) => setFormData({...formData, incident_commander: e.target.value})}
                        />
                    </Form.Group>
                    <Form.Group controlId="formStagingManager" className="mt-3">
                        <Form.Label>Staging Manager</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.staging_manager}
                            onChange={(e) => setFormData({...formData, staging_manager: e.target.value})}
                        />
                    </Form.Group>
                    <Form.Group controlId="formCommunications" className="mt-3">
                        <Form.Label>Communications</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.communications}
                            onChange={(e) => setFormData({...formData, communications: e.target.value})}
                        />
                    </Form.Group>
                    <Form.Group controlId="formNotes" className="mt-3">
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={formData.notes}
                            onChange={(e) => setFormData({...formData, notes: e.target.value})}
                        />
                    </Form.Group>
                    <Form.Group controlId="formRadius" className="mt-3">
                        <Form.Label>Radius (miles)</Form.Label>
                        <Form.Control
                            type="number"
                            value={formData.radius}
                            onChange={(e) => setFormData({...formData, radius: parseFloat(e.target.value)})}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formDate" className="mt-3" style={{ display: 'none' }}>
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formTime" className="mt-3" style={{ display: 'none' }}>
                        <Form.Label>Time</Form.Label>
                        <Form.Control
                            type="time"
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" size="lg" onClick={handleSubmit} disabled={!location || !formData.name || !formData.type}> 
                    Submit
                </Button>    
            </Modal.Footer>
        </Modal>
    );
};

export default StyledIncidentForm;
