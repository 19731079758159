import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import './MobileMode.css';
import MobileToolbar from './MobileToolbar';

const MobileMode = ({ 
    user, 
    onExit,
    homePosition
}) => {
    const map = useMap();
    const [isTracking, setIsTracking] = useState(false);
    const [gpsError, setGpsError] = useState(null);
    const [currentPosition, setCurrentPosition] = useState(null);
    const [currentHeading, setCurrentHeading] = useState(0);
    const centerMarkerRef = useRef(null);
    const watchIdRef = useRef(null);
    const lastPositionRef = useRef(null);
    const lastUpdateRef = useRef(0);
    const tileLayerRef = useRef(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userData, setUserData] = useState(null);
    const markerRef = useRef(null);

    // Parse user data from URL on mount
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const userParam = urlParams.get('user');
        
        try {
            const parsedUserData = userParam ? JSON.parse(decodeURIComponent(userParam)) : null;
            console.log('MobileMode mounted with user data:', parsedUserData);
            
            if (parsedUserData?.userEmail) {
                setUserEmail(parsedUserData.userEmail);
                setUserData(parsedUserData);
            }
        } catch (error) {
            console.error('Error parsing user data:', error);
        }
    }, []);

    useEffect(() => {
        console.log('MobileMode mounted with:', {
            userEmail,
            userData
        });
    }, []);

    console.log('MobileMode initialized with email:', userEmail);

    // Lock map in mobile mode
    useEffect(() => {
        // Disable dragging (pan)
        map.dragging.disable();
        
        // Set zoom constraints
        map.setMinZoom(17);
        map.setMaxZoom(21);
        
        // Force initial zoom level to 17 if lower
        if (map.getZoom() < 17) {
            map.setZoom(17);
        }

        // Enable only zoom controls
        map.touchZoom.enable();
        map.doubleClickZoom.enable();
        map.scrollWheelZoom.enable();
        
        // Disable other controls
        map.boxZoom.disable();
        map.keyboard.disable();

        // Prevent zoom out below 17
        map.on('zoomend', () => {
            if (map.getZoom() < 17) {
                map.setZoom(17);
            }
        });

        return () => {
            // Re-enable all controls when exiting mobile mode
            map.dragging.enable();
            map.setMinZoom(0);
            map.setMaxZoom(22);
            map.boxZoom.enable();
            map.keyboard.enable();
            map.off('zoomend');
        };
    }, [map]);

    // Add map type switching logic
    useEffect(() => {
        const handleZoomEnd = () => {
            const currentZoom = map.getZoom();
            // Updated Google Maps URL with correct hybrid layer
            const googleHybridUrl = 'https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}';
            const osmUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

            // Remove existing tile layer
            if (tileLayerRef.current) {
                map.removeLayer(tileLayerRef.current);
            }

            try {
                // Switch to Google Hybrid at zoom level 19 and above
                tileLayerRef.current = L.tileLayer(
                    currentZoom >= 19 ? googleHybridUrl : osmUrl,
                    {
                        attribution: currentZoom >= 19 
                            ? '&copy; Google Maps'
                            : '&copy; OpenStreetMap contributors',
                        maxZoom: 21,
                        subdomains: currentZoom >= 19 ? [''] : ['a', 'b', 'c'],
                    }
                ).addTo(map);

                // If we have a current position, keep it centered after zoom
                if (currentPosition) {
                    map.setView([currentPosition.lat, currentPosition.lng], currentZoom, {
                        animate: false
                    });
                }
            } catch (error) {
                console.error('Error switching tile layers:', error);
                // Fallback to OSM if Google layer fails
                tileLayerRef.current = L.tileLayer(osmUrl, {
                    attribution: '&copy; OpenStreetMap contributors',
                    maxZoom: 21,
                    subdomains: ['a', 'b', 'c']
                }).addTo(map);
            }
        };

        map.on('zoomend', handleZoomEnd);
        handleZoomEnd(); // Initial setup

        return () => {
            map.off('zoomend', handleZoomEnd);
            if (tileLayerRef.current) {
                map.removeLayer(tileLayerRef.current);
            }
        };
    }, [map, currentPosition]);

    const handleGPSError = useCallback((error) => {
        let errorMessage;
        switch(error.code) {
            case 1: // PERMISSION_DENIED
                errorMessage = 'Please enable GPS access to use mobile mode';
                break;
            case 2: // POSITION_UNAVAILABLE
                errorMessage = 'GPS location information is unavailable';
                break;
            case 3: // TIMEOUT
                errorMessage = 'GPS request timed out';
                break;
            default:
                errorMessage = 'An unknown GPS error occurred';
        }
        setGpsError(errorMessage);
        onExit();
    }, [onExit]);

    const shouldUpdate = useCallback((newPosition, newHeading) => {
        const now = Date.now();
        const lastPos = lastPositionRef.current;
        
        if (!lastPos) return true;
        if (now - lastUpdateRef.current < 500) return false;

        const headingDiff = Math.abs((newHeading || 0) - (lastPos.heading || 0));
        if (headingDiff > 5) return true;

        const distance = map.distance(
            [lastPos.latitude, lastPos.longitude],
            [newPosition.latitude, newPosition.longitude]
        );
        
        return distance > 2;
    }, [map]);

    const updateMarkerPosition = useCallback((latlng, heading) => {
        try {
            if (!centerMarkerRef.current) {
                const icon = L.icon({
                    iconUrl: '/images/icons/policecar/policeCar0.svg',
                    iconSize: [32, 32],
                    iconAnchor: [16, 16]
                });

                centerMarkerRef.current = L.marker(latlng, {
                    icon: icon,
                    rotationAngle: heading || 0,
                    rotationOrigin: 'center center',
                    zIndexOffset: 2000
                }).addTo(map);

                centerMarkerRef.current.bindTooltip(user.name, {
                    permanent: true,
                    direction: 'top',
                    className: 'custom-tooltip police-tooltip',
                    opacity: 0.9
                });
            } else {
                // Smoothly update position and rotation
                const currentPos = centerMarkerRef.current.getLatLng();
                const currentHeading = centerMarkerRef.current.options.rotationAngle;

                // Only update if position actually changed
                if (currentPos.lat !== latlng[0] || currentPos.lng !== latlng[1]) {
                    centerMarkerRef.current.setLatLng(latlng);
                }
                
                // Only update if heading actually changed
                if (currentHeading !== heading) {
                    centerMarkerRef.current.setRotationAngle(heading || 0);
                }
            }
        } catch (error) {
            console.error('Error updating marker position:', error);
        }
    }, [map, user.name]);

    const handlePositionUpdate = useCallback((position) => {
        console.log('Got position update:', {
            coords: position.coords,
            timestamp: new Date().toISOString()
        });

        const { latitude, longitude, heading, speed } = position.coords;
        const latlng = [latitude, longitude];

        // Update marker position
        if (markerRef.current) {
            markerRef.current.setLatLng(latlng);
            if (heading) markerRef.current.setRotationAngle(heading);
        }

        // Center map on user
        map.setView(latlng, map.getZoom(), {
            animate: true,
            duration: 1
        });

        if (!userData?.userName) {
            console.warn('No user name available');
            return;
        }

        const locationData = {
            displayName: userData.userName,
            latitude,
            longitude,
            heading: heading || 0,
            speed: speed || 0
        };

        console.log('Sending location update:', {
            url: 'https://devmerlin.westchesterrtc.com/api/mobile-location',
            headers: {
                'X-User-Email': userEmail,
            },
            body: locationData
        });

        fetch('https://devmerlin.westchesterrtc.com/api/mobile-location', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-User-Email': userEmail,
            },
            credentials: 'include',
            body: JSON.stringify(locationData)
        })
        .then(response => {
            console.log('Server response received:', {
                ok: response.ok,
                status: response.status,
                statusText: response.statusText
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            if (data.error) {
                throw new Error(data.error);
            }
            console.log('Location update successful:', data);
        })
        .catch(error => {
            console.error('Error sending location:', {
                message: error.message,
                timestamp: new Date().toISOString()
            });
        });

        lastPositionRef.current = { latitude, longitude, heading };
        lastUpdateRef.current = Date.now();

    }, [map, userData, userEmail]);

    const handleOutOfService = useCallback(() => {
        // Implement out of service logic
        console.log('Out of service clicked');
    }, []);

    const handleEmergency = useCallback(() => {
        // Implement emergency logic
        console.log('Emergency clicked');
    }, []);

    useEffect(() => {
        document.body.classList.add('mobile-mode');
        setIsTracking(true); // Start tracking immediately in mobile mode
        
        if (!navigator.geolocation) {
            setGpsError('Your device does not support GPS tracking');
            return;
        }

        const watchOptions = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };

        // Get initial position and set zoom
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                map.setView([latitude, longitude], 17);
                handlePositionUpdate(position);
            },
            (error) => setGpsError(`GPS Error: ${error.message}`),
            watchOptions
        );

        // Then start watching position
        watchIdRef.current = navigator.geolocation.watchPosition(
            handlePositionUpdate,
            (error) => setGpsError(`GPS Error: ${error.message}`),
            watchOptions
        );

        return () => {
            document.body.classList.remove('mobile-mode');
            setIsTracking(false);
            if (watchIdRef.current) {
                navigator.geolocation.clearWatch(watchIdRef.current);
            }
            if (centerMarkerRef.current && centerMarkerRef.current._map) {
                centerMarkerRef.current.unbindTooltip();
                map.removeLayer(centerMarkerRef.current);
            }
        };
    }, [map, handlePositionUpdate]);

    if (gpsError) {
        return (
            <div className="gps-error-container">
                <div className="error-message">{gpsError}</div>
            </div>
        );
    }

    return (
        <>
            <MobileToolbar 
                onDesktopMode={onExit}
                currentPosition={currentPosition}
                heading={currentHeading}
                onOutOfService={handleOutOfService}
                onEmergency={handleEmergency}
                homePosition={homePosition}
            />
        </>
    );
};

export default MobileMode; 