import React from 'react';
import { useMap } from 'react-leaflet';
import desktopIcon from '../images/icons/desktop.svg';
import markerIcon from '../images/icons/marker.svg';
import outOfServiceIcon from '../images/icons/offline.svg';
import emergencyIcon from '../images/icons/emergency.svg';
import './MobileToolbar.css';

const getHeadingText = (heading) => {
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
    const index = Math.round(((heading % 360) + 360) % 360 / 45) % 8;
    return directions[index];
};

const MobileToolbar = ({ 
    onDesktopMode, 
    currentPosition, 
    heading,
    onOutOfService,
    onEmergency,
    homePosition
}) => {
    const map = useMap();

    const handleMarkerClick = () => {
        if (currentPosition) {
            map.setView([currentPosition.lat, currentPosition.lng]);
        }
    };

    const handleDesktopMode = () => {
        // First transition to desktop mode
        onDesktopMode();

        // Then simulate home button click by returning to home position
        if (homePosition && Array.isArray(homePosition) && homePosition.length === 2) {
            map.setView(homePosition, 13, { animate: true });
        } else {
            // Fallback to default position
            map.setView([41.0340, -73.7629], 13, { animate: true });
        }
    };

    return (
        <div className="overlay-bottom">
            <div className="mobile-controls-group">
                <button onClick={handleDesktopMode} className="mobile-button">
                    <img src={desktopIcon} alt="Desktop Mode" />
                </button>
                <button onClick={handleMarkerClick} className="mobile-button">
                    <img src={markerIcon} alt="Center Map" />
                </button>
                <div className="position-display">
                    {currentPosition && (
                        <>
                            {currentPosition.lat.toFixed(6)}°, {currentPosition.lng.toFixed(6)}°
                            <br />
                            Heading: {getHeadingText(heading)}
                        </>
                    )}
                </div>
                <button onClick={onOutOfService} className="mobile-button">
                    <img src={outOfServiceIcon} alt="Out of Service" />
                </button>
                <button onClick={onEmergency} className="mobile-button emergency">
                    <img src={emergencyIcon} alt="Emergency" />
                </button>
            </div>
        </div>
    );
};

export default MobileToolbar; 